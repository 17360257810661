.menu-container {
    position: relative;
    display: flex;
    justify-content: center;
    background-color: #00000000;
    align-items: center;
  }
.menu-trigger {
    background: #222;
    color:#fff;
    border-radius: 90px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: right;
    padding: 0px 0px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    border: none;
    vertical-align: middle;
    transition: box-shadow 0.4s ease;
    margin-left: auto; /* Strictly for positioning */
}

.menu-trigger:hover {
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}

.menu-trigger span {
    font-weight: 700;
    vertical-align: middle;
    font-size: 14px;
    margin: 0 10px;
}

.menu-trigger img {
    border-radius: 90px;
}

.menu {
    background: transparent;
    border-radius: 8px;
    position: fixed;
    top: 130px;
    right: 0;
    width: 200px;
    
    padding: 2px 2px 20px 2px ;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility:hidden;

    transform: translateY(-20px);
    height: 60px;
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    ul {
        list-style: none;
        padding: 2px 0px 5px 0px;
        margin: 0;
    }
    li {
        display: block;
        border-bottom: 1px solid #dddddd;
      
    }
   
}
.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}
.menuLabel {
    padding-left: 5px;
   }
