@import url("https://fonts.googleapis.com/css?family=Permanent+Marker");
@import '~bootstrap/scss/bootstrap.scss';
.App {
    width: 95vw;
    margin: 0 0;
    font: size 16px; 
}
html {
    margin: 0;
    font: size 16px; 
}
body {
    margin: 0;
   
    width: 95vw;
    background: url(../images/granite.jpg) !important;
   
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    display: flex;
    flex-direction: column;

    background-color: #117;
    min-height: 20vh;
    
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}
header {
   
   
    //position: fixed;
    //top: 0;

    z-index: 20;

    background: linear-gradient(0deg, #661100, #330000);
    width: 100vw;
    height: 80px;
    padding: 1px;

    text-align: center;
    h1 {
        margin: 2px;
        color: #ffff00;
        font-family: "Permanent Marker", Cursive;
        font-size: 1.9rem;
        letter-spacing: 0.3rem;
    }
    h2 {
        margin: 2px;
        color: #ffffff;
        font-family: "Permanent Marker", Cursive;
        font-size: 1.0rem;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

nav {
    li {
    color: #ffff00; 
    }
    a:visited, a:link {
                color: #aad;
            }
}
// nav {
//     background: transparent; // linear-gradient(180deg, #222, #444444);
//     padding: 5px;
//     height: 100px;
//     margin: 6px 0 2px 0;
//     overflow: hidden;

//     li {
//         display: inline;
//         background-color: transparent; //#111;
//         margin: 10px 5px;
//         color: #ffff00;
//         list-style-type: none;
//         padding: 5px;
        
       
//     }
//     .logo {
//         height: 40px;
//         padding-right: 20px;
//         margin-top: -5px;
//     }
//     ul {
//         margin: 0;
//     }
//     a {
//         text-decoration: none;
//     }
//     a:link,
//     a:visited {
//         color: #aad;
//     }
//     a:hover {
//         color: #eee;
    
//     }
  
//     .nav-right {
//         float: right;
//         margin-right: 15px;
//     }
//     .nav-left {
//         float: left;
//         margin-left: 5px;
//     }
//     .menu-container{
//         height: 20px;
//         padding: 0;
//     }
// }

.main {
    position: relative;
    margin: 50px auto;

    width: 85vw !important;
    min-height: 70vh;
    padding: 5px 5px;
    background-color: #ffffd9bc;//antiquewhite;
    box-shadow: 10px 10px;
    overflow: hidden;
    font-size: 18px;
}
.clearfix {
    display: block;
}

.clearfix:after {
    clear: both;
    content: "";
    display: block;
    height: 0;
    visibility: hidden;
}
.error {
    font-weight: bold;
    color: red;
    min-height: 20px;
}
label {
    h5 {
        margin: 5px 0 2px 0;
    }
}
button {
    margin: 5px auto;
}
footer {
    background: linear-gradient(0deg, #661100, #330000);
    color: yellow;
    position: fixed;
    height: 34px;
    bottom: 0px;
    padding-top: 5px;
    width: 100vw;
}
.btn-category {
    display: block;
    background: #ffff00;
}

.toc {
    margin: 80px auto;
    width: 90vw;
    padding: 5px 5px 20px;

    text-align: center;

    h2 {
        width: 440px;
        background-color: #000;
        color: #ffff00;
        border: #ffff00 solid 2px;
        box-shadow: 5px 5px #110;
        text-align: center;
        padding: 5px 5px 5px 5px;
        margin: 2vh auto 5px;
        font-family: "Permanent Marker", Cursive;
    }

    ul {
        padding: 0;
        background-color: #22002277;
        display: grid;
        grid-column-gap: 1rem;
        width: 400px;
        text-align: center;
        margin: 0 auto;

        li {
            display: block;

            box-shadow: 5px 5px #000;
            font-family: "Permanent Marker", Cursive;
            color: #003163;
            border-radius: 5px;
            padding: 5px;
            margin: 10px auto;
            width: 90%;
            text-align: center;
            font-weight: bold;
            font-size: 1.1rem;
        }
        li.shared {
            background: linear-gradient(0deg, #555, #bbb);
        }
        li.user {
            background: linear-gradient(0deg, #aa9900, #ffbb11);
            //background-color: #ccaa00;
        }
        a {
            text-decoration: none;
        }
        a:hover {
            color: #7777ff;
        }
       
    }
    form {
        display: block;
        margin: 20px auto;
        padding: 5px;
        background: #22002277;
        width: 70%;
        label {
            display: inline;
            color: #ffff00;
            margin: 50px auto;
            padding: 20px 5px 2px 2px;
        }
    }
}
    
iframe {
    display: block;
    width: 50%;
    min-height: 0vh;
    height: 70vh;
    margin: 0 auto;
    text-align: center;
}


.glyph-tray {
    margin-left: 10px;
}
.glyph-bottom {
    position: absolute;
   bottom: 5px;
}



.icon-container {
    padding: 5px 2px;
}
.ink {
    color: #220044;
}
.qricon {
    width: 18px;
    margin: 10px 5px -2px 5px;
  
    height: 18px;
}
.form-field {
    display: inline-block;
    width: 140px;
    margin: 5px;
    text-align: right;
}
input,
select,
textarea {
    display: inline;
    width: 50%;
}
textarea {
    height: 15em;
}
.photo {
    position: relative;
    margin: 12px 20px 5px 10px;
    width: 75px;
    height: auto;
    float: right;
    box-shadow: 5px 5px #000;
    z-index: 2;
    transform: rotate(10deg);
}
.menuLabel {
    display: none;
}
.linksite {
    display: block;
    margin: 0 auto;
    height: 70vh;
    text-align: center;
}
@media (min-width: 600px) {
    .toc {
        ul {
            grid-template-columns: repeat(2, 1fr);
            width: 90%;
        }
        h2 {
            width: 550px;
        }
    }
    .photo {
        width: 100px;
        height: auto;
    }
    .glyph {
        padding: 0px 20px;
    }
}
        @media (min-width: 900px) {
    .toc {
        ul {
            grid-template-columns: repeat(3, 1fr);
            width: 92%x;
        }
        h2 {
            width: 850px;
        }
    }
    .photo {
        width: 150px;
        height: auto;
    }
    .menuLabel {
        display: inline;
    }
    .glyph {
        padding: 0px 5px;
    }
}

.construction {
    position: relative;
    width: 60%;
    margin: 160px auto;
    background-color: yellow;
    color: #000;
    padding: 10px;
    text-align: center;
}
.voidheader {
    margin: -100px 0 0 0;
}
.transback {
    background-color: transparent !important;
}
.blue {
    background-color: #00f;
    height: 20px !important;
    a {
        height: 20px ;
    }
}
.short-drop {
    width: 140px !important;
}
.homeImage {
    width: 50px;
}
img.badgey {

    width: 250px;
  
}
.rotateLeft {
    margin-top: 5px;
    transform-origin: 0 0;
    transform: rotate(-2deg);
}
.shrinkwrap {
    width: fit-content !important;
}

