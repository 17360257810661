
.book {
    background-image: url('../../images/book.jpg');
    display: block;
    width:85vw;
    max-width: 500px;
    height: 600px;
    margin: 60px auto ;
    padding: 10px;

    section {
        background : white;
        display: block;
        border: 1px black solid;
        padding: 5px;
        margin: 10px auto ;
        width: 100%;
        max-width: 400px;
        height: auto;
        box-shadow: 10px 10px;
        overflow: hidden;

        h2{
            font-family: "Permanent Marker", Cursive;
    
            text-align: center;
        }
        H4 {
            margin-bottom: 1px;
        }
      
       .checkfix {
           
            display: inline-block;
            width: 100%;
            
            h5 {
                display: inline;
                vertical-align: middle;
                width: 150px;
               
                
            }


            input { 
                width: 20px;
                margin: 5px 2px;
               
                vertical-align: middle;
            }
        }
       
        button {
            font-family: "Permanent Marker", Cursive;
            font-size: 1.1em;
           // border-radius: 5px;
            display: block;
            margin: 10px auto;
            padding: 5px;
            //background: linear-gradient(45deg,#0000ff,#5555ff);
           // color: white;
            box-shadow: 2px 2px #000;
        }
        a {
            font-family: "Permanent Marker", Cursive;
            font-size: 1.1em;
            text-decoration: none;
            display: inline-block;
            margin: 5px;
            padding: 5px;
          //  background-color:#050;
          //  background: linear-gradient(45deg,#004400,#006600);
            box-shadow: 2px 2px #000;
           // border-radius: 5px;
        }
        a:link, a:visited{
            color: #00ff00;
            
        }
        .tray {
            width: 150px;
            display: block;
            margin: 0 auto;
            text-align: center;
        }
        a:hover {
            color: #ccffcc;
        }
        
    } 
   
    
}
.playStore {
    
    margin: 0 auto;
    display: block;
    width: 200px;
    height: auto;
    text-align:center;
    object-fit: contain ;
}
.classTray {
    
    width: 200px;
    text-align:center;
    //margin: 0 auto;
   
    
}
.playLink {
    display: block;
    margin: 0px auto;
    width: 200px !important;
    text-align:center;
  }

@media screen and (min-width: 768px ) {

    .book {
        width: 60vw;

        max-width: 500px;
        section {
            width: 80%;
            max-width: 400px;
            .tray {
                width: 80%;
            }
        }
        
    }   
}
