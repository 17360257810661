section {
    position: relative;
    background : white;
    border: 1px black solid;
    padding: 5px;
    margin: 160px auto 20px ;
    width: 40%;
    min-width: 400px;
    height: 40vh;
    box-shadow: 10px 10px;
    overflow: hidden;
    text-align: center;
    h2{
        font-family: "Permanent Marker", Cursive;
        font-size: 1.4rem   ;
        color: #220044;
        text-align: center;
    }
}