.helpcard {
    display: block;
   
    position: relative;
    top: 70px;
  
    margin: 0 auto 120px;
    overflow-y: auto;
    padding: 2px 5px 10px 5px;
    width: 80vw;
    background: linear-gradient(180deg,#fdd,#fff);
          border-radius: 20px;
     min-height: 60% !important;
      padding: 10px;
     box-shadow: 5px 5px 2px #222;
     h2 {
         text-align: center;

     }
   
}
.pen {
    color: #307;
}
.spacer100 {
height: 100px;
}